import React, { useState, useEffect } from "react";
import { TableSelectRow, TableCell, TextInput } from "carbon-components-react";

import TableBody from "./../gatsby-theme-carbon/components/DataTable/TableBody";
import TableRow from "./../gatsby-theme-carbon/components/DataTable/TableRow";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const filterContent = (rows, currentPage, pageSize) => {
  let value =
    currentPage && pageSize
      ? rows.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      : rows;
  return value;
};

const getOrderValues = (val) => {
  return val.map((row) => {
    let value = null;
    for (const cell of row.cells)
      if (cell.info.header === "order") {
        value = { value: cell.value, id: row.id };
      }
    return value;
  });
};

function QuestionsTableBody({
  rows,
  getSelectionProps,
  getRowProps,
  currentPage,
  pageSize,
  isCustomTest,
  setAddedQuestions,
  addedQuestions,
  withSelection,
  setIsDragging,
}) {
  const [paginatedContent, setPaginatedContent] = useState(
    filterContent(rows, currentPage, pageSize)
  );
  const [order, setOrder] = useState(getOrderValues(rows));

  useEffect(() => {
    setPaginatedContent(filterContent(rows, currentPage, pageSize));
  }, [rows, currentPage, pageSize]);

  useEffect(() => {
    setOrder(getOrderValues(rows));
  }, [rows]);

  const onDragStart = () => {
    if (typeof setIsDragging !== "undefined") setIsDragging(true);
  };

  const onDragEnd = (result) => {
    if (typeof setIsDragging !== "undefined") setIsDragging(false);
    let target = result.destination.index;
    let source = result.source.index;
    let new_questions = reorder(addedQuestions, source, target);
    new_questions = new_questions.map((q, i) => ({ ...q, order: i + 1 }));
    setAddedQuestions(new_questions);
  };

  const updateOrder = (val) => {
    let new_questions = addedQuestions.map((m) => {
      if (m.id !== val) return m;
      let item = order.find((o) => o.id === val);
      return {
        id: val,
        order:
          item && item.value !== "" && item.value !== null ? parseInt(item.value) : 1,
      };
    });
    setAddedQuestions(new_questions);
  };

  if (typeof setIsDragging === "undefined") {
    return (
      <TableBody>
        {paginatedContent.map((row, index) => {
          return (
            <TableRow
              {...getRowProps({
                row,
              })}
            >
              {withSelection && <TableSelectRow {...getSelectionProps({ row })} />}
              {row.cells.map((cell) => (
                <TableCell key={cell.id}>{cell.value}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable">
        {(provided) => {
          return (
            <TableBody innerRef={provided.innerRef}>
              {paginatedContent.map((row, index) => {
                let row_order_val = order.find((o) => o.id === row.id);
                return (
                  <Draggable key={row.id} draggableId={row.id} index={index}>
                    {(provided) => {
                      return (
                        <TableRow
                          {...getRowProps({
                            row,
                          })}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}
                        >
                          {withSelection && (
                            <TableSelectRow {...getSelectionProps({ row })} />
                          )}
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                              {cell.info.header === "order" && isCustomTest ? (
                                <TextInput
                                  labelText=""
                                  id={cell.id}
                                  value={row_order_val ? row_order_val.value : 1}
                                  onChange={(e) =>
                                    setOrder(
                                      order.map((o) => {
                                        if (o.id !== row.id) return o;
                                        let new_val = !isNaN(e.target.value)
                                          ? e.target.value
                                          : 1;
                                        return { id: o.id, value: new_val };
                                      })
                                    )
                                  }
                                  onBlur={(e) => updateOrder(row.id)}
                                />
                              ) : (
                                cell.value
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    }}
                  </Draggable>
                );
              })}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

export default QuestionsTableBody;
