import {
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TextRun,
  VerticalAlign,
  WidthType,
  AlignmentType,
  Media,
} from "docx";
import streamSaver from "streamsaver";
import { parse } from "himalaya";
import { decode } from "he";
import axios from "axios";
import "web-streams-polyfill/dist/ponyfill.es6.mjs";
import probe from "probe-image-size";

const docConfig = {
  creator: "AUPO Question Bank",
  title: "",
  description: "",
  numbering: {
    config: [
      {
        levels: [
          {
            level: 0,
            format: "decimal",
            text: "%1.",
            alignment: AlignmentType.START,
            style: {
              paragraph: {
                indent: { left: 720, hanging: 260 },
              },
            },
          },
        ],
        reference: "my-number-numbering-reference",
      },
    ],
  },
  styles: {
    paragraphStyles: [
      {
        id: "AUPOHeading3",
        name: "Heading 3",
        basedOn: HeadingLevel.HEADING_3,
        next: HeadingLevel.HEADING_3,
        quickFormat: true,
        run: {
          font: "Arial",
          italics: false,
          color: "000000",
          size: 24,
          allCaps: true,
        },
        paragraph: {
          spacing: {
            before: 380,
            after: 220,
          },
        },
      },
      {
        id: "AUPOHeading5",
        name: "Heading 5",
        basedOn: HeadingLevel.HEADING_5,
        next: HeadingLevel.HEADING_5,
        quickFormat: true,
        run: {
          font: "Arial",
          italics: false,
          color: "000000",
          size: 16,
          allCaps: true,
        },
        paragraph: {
          spacing: {
            before: 220,
            after: 220,
          },
        },
      },
    ],
  },
};

const tags = {
  h3: { style: "AUPOHeading3", text: "", font: { name: "Arial" } },
  h5: { style: "AUPOHeading5", text: "", font: { name: "Arial" } },
};

let letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
];

let media = [];

function docxExport(
  addedQuestions,
  questionsData,
  testTitle,
  ExportAnswers = false,
  allFileFile = null
) {
  setMedia(allFileFile);
  return new Promise((resolve, reject) => {
    let data = addedQuestions.map(({ id }) =>
      questionsData.find((question) => question.id === id)
    );
    let body = constructHTML(data, ExportAnswers);
    body = decode(body.replace(/\r?\n|\r/g, ""));
    convertToDoc(body, ExportAnswers).then((doc) => {
      Packer.toBlob(doc).then((blob) => {
        const fileStream = streamSaver.createWriteStream(`${testTitle}.docx`);
        const readable = blob.stream();
        if (window.WritableStream && readable.pipeTo) {
          return readable.pipeTo(fileStream).then(() => {
            resolve();
          });
        } else {
          reject();
        }
      });
    });
  });
}

export default docxExport;

function setMedia(allFileFile) {
  media =
    !media.length && allFileFile && allFileFile.edges && allFileFile.edges.length > 0
      ? allFileFile.edges.map(({ node }) => ({
          id: node.drupal_id,
          url: node.localFile.url,
          size: node.localFile.size,
        }))
      : [];
}

async function convertToDoc(body, ExportAnswers) {
  let doc = new Document(docConfig);
  const parsedBody = parse(body);
  let paragraphs = await doHtmlReplacements(parsedBody, doc, ExportAnswers);
  doc.addSection({
    properties: {},
    children: paragraphs,
  });

  return doc;
}

function constructHTML(data, ExportAnswers) {
  let html = "";
  if (ExportAnswers) {
    html += "<table>";
    html += "<tr>";
    html += "<td><p>Question</p></td>";
    html += "<td><p>Answer</p></td>";
    html += "</tr>";
    data.forEach((question, index) => {
      let valid_question = question.answers.find((a) => a.valid);
      html += "<tr>";
      html += `<td><p>${index + 1} - ${question.identifier}</p></td>`;
      html += `<td><p>${letters[valid_question.position]}</p></td>`;
      html += "</tr>";
    });
    html += "</table>";
  } else {
    data.forEach((question, index) => {
      html += `<h3>Question ${index + 1}</h3>`;
      html += question.question;
      html += "<h5>Mark your answer:</h5>";
      html += "<table>";
      question.answers.forEach((answer) => {
        html += "<tr>";
        html += "<td></td>";
        html += `<td>${answer.answer}</td>`;
        html += "</tr>";
      });
      html += "</table>";
    });
  }
  return html;
}

async function doHtmlReplacements(nodes, doc, ExportAnswers) {
  let paragraphs = [];
  for (let i in nodes) {
    let node = nodes[i];
    if (nodeIsText(node)) {
      paragraphs.push(genPlainParagraph(node));
    } else if (nodeHasChildren(node)) {
      if (node.tagName === "table") {
        let table_para = await genTable(node, ExportAnswers);
        paragraphs.push(table_para);
        paragraphs.push(new Paragraph({ break: {} }));
      } else if (node.tagName === "h3" || node.tagName === "h5") {
        paragraphs.push(genHeader(node));
      } else if (node.tagName === "p") {
        let para = await genMainParagraph(node, null, doc);
        paragraphs.push(para);
      } else if (node.tagName === "ul" || node.tagName === "ol") {
        for (const li in node.children) {
          let li_para = await genMainParagraph(node.children[li], node.tagName, doc);
          paragraphs.push(li_para);
        }
      }
    }
  }
  return paragraphs;
}

async function doCellHtmlReplacements(nodes) {
  let paragraphs = [];
  for (let i in nodes) {
    if (nodes[i].tagName === "p") {
      let para = await genMainParagraph(nodes[i]);
      paragraphs.push(para);
    }
  }
  return paragraphs;
}

function genPlainParagraph(node) {
  return createParagraph({
    children: [
      new TextRun({
        text: node.content,
        size: 16,
        color: "000000",
        font: { name: "Arial" },
      }),
    ],
  });
}

function genHeader(node) {
  let tmpObj = { ...tags[node.tagName] };
  if (node.children && node.children.length) {
    for (let child of node.children) {
      if (nodeIsText(child)) {
        tmpObj.text = child.content;
        break;
      }
    }
  }
  return new Paragraph(tmpObj);
}

async function genTable(node, isAnswersTable) {
  let table_w = isAnswersTable ? WidthType.PERCENTAGE : WidthType.AUTO;
  let rows = [];
  for (let qi = 0; qi < node.children.length; qi++) {
    const { children } = node.children[qi];
    let tableChildren = [];
    for (let child_index = 0; child_index < children.length; child_index++) {
      const child = children[child_index];
      let ch = [];
      let marginX = 180;
      if (child.children && child.children.length) {
        ch = await doCellHtmlReplacements(child.children);
      } else {
        ch = [
          new Paragraph({
            children: [
              new TextRun({
                text: `${letters[qi]})`,
                color: "b5b5b5",
                size: 16,
                font: { name: "Arial" },
              }),
            ],
          }),
        ];
        marginX = 420;
      }
      tableChildren.push(
        new TableCell({
          children: ch,
          verticalAlign: VerticalAlign.CENTER,
          margins: {
            top: 90,
            right: marginX,
            bottom: 120,
            left: marginX,
          },
        })
      );
    }
    let tableRow = new TableRow({
      children: tableChildren,
    });
    rows.push(tableRow);
  }
  const table = new Table({
    spacing: {
      before: 200,
      after: 800,
    },
    width: {
      size: 100,
      type: table_w,
    },
    rows,
  });
  return table;
}

async function genMainParagraph(node, listType = null, doc = null) {
  let is_list = node.type === "element" && node.tagName === "li";
  let obj = {
    children: [],
  };
  for (const child of node.children) {
    if (nodeIsText(child)) {
      obj.children.push(
        new TextRun({
          text: child.content,
          size: 16,
          color: "000000",
          font: { name: "Arial" },
        })
      );
    } else if (child.type === "element") {
      if (child.tagName === "strong" || child.tagName === "em") {
        if (nodeIsText(child.children[0])) {
          obj.children.push(
            new TextRun({
              bold: child.tagName === "strong",
              italics: child.tagName === "em",
              text: child.children[0].content,
              size: 16,
              color: "000000",
              font: { name: "Arial" },
            })
          );
        } else {
          for (const grandchild of child.children) {
            if (nodeIsText(grandchild)) {
              obj.children.push(
                new TextRun({
                  text: grandchild.content,
                  size: 16,
                  color: "000000",
                  font: { name: "Arial" },
                })
              );
            } else if (grandchild.tagName === "img") {
              let image = await genImage(grandchild, doc);
              if (image) obj.children.push(image);
            }
          }
        }
      } else if (child.tagName === "img" && doc) {
        let image = await genImage(child, doc);
        if (image) obj.children.push(image);
      }
    }
  }
  return createParagraph(obj, is_list, listType);
}

async function genImage(node, doc) {
  let image;
  let image_id = node.attributes.find((attr) => attr.key === "data-entity-uuid");
  if (image_id) {
    let image_data = media.find((item) => item.id === image_id.value);
    if (image_data) {
      let buff = await generateImageBuffer(image_data.url);
      let image_size = await probe(image_data.url);
      let max_size = 260;
      let new_image_size = {
        width: max_size,
        height: max_size,
      };
      if (image_size) {
        if (image_size.width > max_size) {
          let ratio = (image_size.height / image_size.width).toFixed(2);
          new_image_size.width = max_size;
          new_image_size.height = Math.round(max_size * ratio);
          if (new_image_size.height > max_size) {
            ratio = (image_size.width / image_size.height).toFixed(2);
            new_image_size.height = max_size;
            new_image_size.width = Math.round(max_size * ratio);
          }
        } else if (image_size.height > max_size) {
          let ratio = (image_size.width / image_size.height).toFixed(2);
          new_image_size.height = max_size;
          new_image_size.width = Math.round(max_size * ratio);
        } else {
          new_image_size = {
            width: image_size.width,
            height: image_size.height,
          };
        }
      }
      image = Media.addImage(doc, buff, new_image_size.width, new_image_size.height);
    }
  }
  return image;
}

function createParagraph(obj, isList = null, listType = null) {
  obj.spacing = {
    before: 240,
    after: 120,
  };
  if (isList && listType === "ul") {
    obj.bullet = {
      level: 0,
    };
  } else if (isList && listType === "ol") {
    obj.numbering = {
      reference: "my-number-numbering-reference",
      level: 0,
    };
  }
  return new Paragraph(obj);
}

function nodeHasChildren(node) {
  return node.children && node.children.length > 0;
}

function nodeIsText(node) {
  return node.type === "text";
}

async function generateImageBuffer(url) {
  let imageReq = await axios.get(url, {
    responseType: "arraybuffer",
  });
  let buff = Buffer.from(imageReq.data, "binary");
  return buff;
}
