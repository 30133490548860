export default (force) => {
  if (force && force.length > 0) {
    return force;
  } else if (typeof localStorage !== "undefined") {
    let added_obj = localStorage.getItem("addedQuestions");
    if (added_obj !== null) {
      return JSON.parse(added_obj);
    } else {
      return [];
    }
  } else {
    return [];
  }
};
