import React from "react";
import striptags from "striptags";
import { Modal, RadioButton, UnorderedList, ListItem } from "carbon-components-react";

import { allowedTags } from "./../data";

function QuestionPreviewModal({
  previewQuestionObj,
  previewing,
  setPreviewing,
  onPreviewSubmit,
  primaryButtonText,
}) {
  function QuestionPreview({ question }) {
    return (
      <div className="aupo-question-preview">
        <div
          className="question-txt"
          dangerouslySetInnerHTML={{ __html: striptags(question.question, allowedTags) }}
        />
        <span className="bx--modal-header__label">Select your answer:</span>
        <ol className="answer-list">
          {question.answers.map((a) => {
            return (
              <li key={a.id} className={a.valid ? "valid" : "invalid"}>
                <RadioButton
                  id={a.id}
                  labelText=""
                  value={a.id}
                  disabled={!a.valid}
                  checked={a.valid}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: striptags(a.answer, allowedTags) }}
                ></div>
              </li>
            );
          })}
        </ol>
        <UnorderedList>
          {question.categories_obj.length > 0 && (
            <ListItem>
              Category: <>{question.categories_obj}</>
            </ListItem>
          )}
          {question.keywords_obj.length > 0 && (
            <ListItem>
              Keywords: <>{question.keywords_obj}</>
            </ListItem>
          )}
        </UnorderedList>
      </div>
    );
  }

  return (
    <Modal
      size="lg"
      modalHeading=""
      primaryButtonText={primaryButtonText}
      secondaryButtonText="Close"
      modalLabel="Question Preview"
      open={previewing !== null}
      onRequestClose={(e) => setPreviewing(null)}
      onRequestSubmit={onPreviewSubmit}
    >
      {previewQuestionObj !== null && <QuestionPreview question={previewQuestionObj} />}
    </Modal>
  );
}

export default QuestionPreviewModal;
