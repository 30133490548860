export default (section) => {
  let data = [
    {
      header: "Question",
      key: "summary",
    },
    {
      header: "",
      key: "actions",
    },
    {
      header: "Categories",
      key: "categories",
    },
    {
      header: "Keywords",
      key: "keywords",
    },
  ];
  if (section !== "home") {
    data.push({
      header: "Order",
      key: "order",
    });
  }
  return data;
};
