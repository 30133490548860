import React from "react";
import { Row, Grid, Column } from "gatsby-theme-carbon";
import logo from "../../images/logo-aupo.png";
import {
  footer,
  grid,
  nav,
  listItem,
  content,
} from "gatsby-theme-carbon/src/components/Footer/Footer.module.scss";

const CustomFooter = () => {
  const links = {
    firstCol: [
      { href: "https://aupo.org/meetings-overview", linkText: "Meetings" },
      { href: "https://aupo.org/programs-services", linkText: "Programs & Services" },
      { href: "https://aupo.org/about-aupo", linkText: "About AUPO" },
    ],
    secondCol: [
      { href: "https://aupo.org/membership-overview", linkText: "Membership" },
      { href: "https://aupo.org/aupo-privacy", linkText: "Privacy" },
      { href: "https://aupo.org/terms", linkText: "Terms" },
    ],
  };

  return (
    <footer className={footer}>
      <Grid className={grid}>
        <Row>
          <Column colLg={2} colMd={2}>
            <ul className={nav}>
              {links.firstCol &&
                links.firstCol.map((link, i) => (
                  <li key={i} className={listItem}>
                    <a
                      href={link.href}
                      aria-label={link.linkText}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.linkText}
                    </a>
                  </li>
                ))}
            </ul>
          </Column>
          <Column colLg={2} colMd={2}>
            <ul className={nav}>
              {links.secondCol &&
                links.secondCol.map((link, i) => (
                  <li key={i} className={listItem}>
                    <a
                      href={link.href}
                      aria-label={link.linkText}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.linkText}
                    </a>
                  </li>
                ))}
            </ul>
          </Column>
          <Column className={content} colLg={4} colMd={4} colSm={3} offsetLg={2}>
            <>
              <img
                src={logo}
                alt="Association of University Professors of Ophthalmology"
                style={{ width: 320, height: "auto" }}
              />
              <p>
                <br />
                655 Beach Street San Francisco, California 94109
                <br />
                Telephone: (415) 561-8548
              </p>
            </>
          </Column>
        </Row>
      </Grid>
    </footer>
  );
};

export default CustomFooter;
