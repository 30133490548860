// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-custom-test-js": () => import("./../../../src/pages/custom-test.js" /* webpackChunkName: "component---src-pages-custom-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */)
}

