import React from "react";
import Header from "gatsby-theme-carbon/src/components/Header";
import logo from "../../images/logo-aupo-header.png";

const CustomHeader = (props) => (
  <Header {...props}>
    <img
      src={logo}
      alt="Association of University Professors of Ophthalmology"
      style={{
        height: 48,
        width: "auto",
        marginRight: 15,
        padding: "6px 12px 0px",
        background: "white",
      }}
    />
    <span>AUPO &nbsp;</span> Medical Student Exam Question Bank
  </Header>
);

export default CustomHeader;
