export default (data, setCategoriesData, setKeywordsData) => {
  if (
    data.allTaxonomyTermCategories &&
    data.allTaxonomyTermCategories.edges &&
    data.allTaxonomyTermCategories.edges.length
  ) {
    setCategoriesData(
      data.allTaxonomyTermCategories.edges.map(({ node }) => ({
        name: node.name,
        id: node.drupal_id,
        color: node.field_color,
      }))
    );
  }
  if (
    data.allTaxonomyTermTags &&
    data.allTaxonomyTermTags.edges &&
    data.allTaxonomyTermTags.edges.length
  ) {
    setKeywordsData(
      data.allTaxonomyTermTags.edges.map(({ node }) => ({
        name: node.name,
        id: node.drupal_id,
      }))
    );
  }
};
