import faker from "faker";
import { removeTags, excerptify, shuffle, removeSpans } from "./../util";

export default (allNodeQuestion, site) => {
  let edges =
    allNodeQuestion.edges && allNodeQuestion.edges.length > 0
      ? allNodeQuestion.edges
      : [];
  let drupalUrl =
    site.siteMetadata && site.siteMetadata.drupalUrl ? site.siteMetadata.drupalUrl : "";

  let questions = edges.map(({ node }, index) => {
    let my_question_id = node.drupal_id;
    let my_cat_ids =
      node.relationships.field_categories &&
      node.relationships.field_categories.length > 0
        ? node.relationships.field_categories.map(({ drupal_id }) => drupal_id)
        : [];
    let my_word_ids =
      node.relationships.field_keywords && node.relationships.field_keywords.length > 0
        ? node.relationships.field_keywords.map(({ drupal_id }) => drupal_id)
        : [];
    let my_answers =
      node.field_wrong_answers && node.field_wrong_answers.length
        ? node.field_wrong_answers.map(({ processed }, a_index) => ({
            id: faker.random.uuid(),
            order: a_index,
            answer: removeSpans(processed),
            valid: false,
          }))
        : [];

    my_answers.push({
      id: faker.random.uuid(),
      answer: removeSpans(node.field_answer.processed),
      valid: true,
    });

    let my_answers_suffled_ids = shuffle(my_answers.map((a) => a.id));

    let my_answers_suffled = my_answers_suffled_ids.map((id) =>
      my_answers.find((a) => id === a.id)
    );

    let question_and_images = removeSpans(node.field_question.processed).replace(
      /src="\/sites/g,
      `src="${drupalUrl}/sites`
    );

    return {
      id: my_question_id,
      identifier: node.title,
      question: question_and_images,
      summary: `${node.title}: ${excerptify(removeTags(node.field_question.processed))}`,
      category_ids: my_cat_ids,
      keyword_ids: my_word_ids,
      answers: my_answers_suffled.map((a, ai) => ({ ...a, position: ai })),
      order: index + 1,
    };
  });
  return questions;
};
