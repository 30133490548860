import React from "react";
import {
  TableHead,
  TableRow,
  TableSelectAll,
  TableHeader,
} from "carbon-components-react";

function QuestionsTableHead({
  headers,
  getSelectionProps,
  getHeaderProps,
  withSelection,
}) {
  return (
    <TableHead>
      <TableRow>
        {withSelection && <TableSelectAll {...getSelectionProps()} />}
        {headers.map((header) => (
          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default QuestionsTableHead;
