import React from "react";
import { Tag, Button } from "carbon-components-react";
import { View16 } from "@carbon/icons-react";

export default (questionsData, categoriesData, keywordsData, setPreviewing) => {
  function RowActions({ id, setPreviewing }) {
    return (
      <Button
        kind="ghost"
        title="Preview"
        onClick={(e) => {
          setPreviewing(id);
        }}
      >
        <View16 className="bx--btn__icon" />
      </Button>
    );
  }

  return questionsData.map((props, i) => {
    const my_cats = categoriesData.filter((cat) => props.category_ids.includes(cat.id));
    const my_cat_labels = my_cats.map((cat, ci) => (
      <Tag type={cat.color} key={`key-${ci}`}>
        {cat.name}
      </Tag>
    ));
    const my_cat_labels_str = my_cats.map((cat) => cat.name).join(", ");

    const my_words = keywordsData.filter((word) => props.keyword_ids.includes(word.id));
    const my_keyword_labels = my_words.map((word, ci) => (
      <Tag key={`key-${ci}`}>{word.name}</Tag>
    ));
    const my_keyword_labels_str = my_words.map((word) => word.name).join(", ");
    return {
      ...props,
      categories: my_cat_labels_str,
      categories_obj: my_cat_labels,
      keywords: my_keyword_labels_str,
      keywords_obj: my_keyword_labels,
      actions: (
        <>
          <RowActions id={props.id} setPreviewing={setPreviewing} />
        </>
      ),
    };
  });
};
