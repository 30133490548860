import "./src/styles/index.scss";
import { UserContextProvider } from "@parallelpublicworks/entitysync";
import Default from "./src/gatsby-theme-carbon/templates/Default";
import React from "react";
import { LoginWrap } from "./src/components";

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>{element}</UserContextProvider>
);

export const wrapPageElement = ({ element, props }) => {
  let pageContext = { ...props.pageContext };
  let all_sites_data_exists =
    props.data && props.data.allSitePage && props.data.allSitePage.edges;
  let page_node = all_sites_data_exists
    ? props.data.allSitePage.edges.find(({ node }) => {
        return (
          node.path && node.fields && node.fields.frontmatter && node.path === props.path
        );
      })
    : null;
  if (page_node) pageContext.frontmatter = page_node.node.fields.frontmatter;
  if (
    pageContext.relativePagePath.includes("questions") ||
    pageContext.relativePagePath.includes("custom-test")
  ) {
    return (
      <LoginWrap location={props.location}>
        <Default location={props.location} pageContext={pageContext}>
          {element}
        </Default>
      </LoginWrap>
    );
  }
  return (
    <Default location={props.location} pageContext={pageContext}>
      {element}
    </Default>
  );
};
