import React from "react";
import { SettingsAdjust16, Close16, Download16, Shuffle16 } from "@carbon/icons-react";
import { isChrome, isOpera, isEdge } from "react-device-detect";
import {
  TableToolbarMenu,
  Checkbox,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
} from "carbon-components-react";

function QuestionsToolbarContent({
  getBatchActionProps,
  onInputChange,
  categoryFilters,
  toggleCategoryFilter,
  setCategoryFilters,
  setDownloading,
  categoriesData,
  isCustomTest = false,
  randomizeQuestions,
}) {
  const browserUnsupported = !isChrome && !isEdge && !isOpera;
  return (
    <TableToolbarContent>
      <TableToolbarSearch
        tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
        onChange={onInputChange}
        placeHolderText="Search..."
      />
      {isCustomTest && (
        <>
          <Button
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            size="small"
            kind="secondary"
            renderIcon={Shuffle16}
            onClick={(e) => randomizeQuestions()}
          >
            Randomize
          </Button>
          <Button
            tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
            size="small"
            kind="primary"
            renderIcon={Download16}
            onClick={(e) => setDownloading(true)}
            disabled={browserUnsupported}
          >
            Download
          </Button>
        </>
      )}
      {categoryFilters.length > 0 && (
        <Button
          tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
          onClick={() => {
            setCategoryFilters([]);
          }}
          size="small"
          kind="ghost"
          renderIcon={Close16}
        >
          Clear filters
        </Button>
      )}
      <TableToolbarMenu
        tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
        renderIcon={SettingsAdjust16}
        className="questions-filter-dropdown"
        menuOptionsClass="questions-facet"
        title="Filter"
      >
        <>
          <fieldset className="bx--fieldset">
            <legend className="bx--label">Filter by category:</legend>
            {categoriesData.map((cat) => (
              <Checkbox
                labelText={cat.name}
                checked={categoryFilters.includes(cat.id)}
                id={cat.id}
                key={cat.id}
                value={cat.id}
                onChange={(checked, id, evt) => {
                  toggleCategoryFilter(checked, id, evt);
                }}
              />
            ))}
          </fieldset>
        </>
      </TableToolbarMenu>
    </TableToolbarContent>
  );
}

export default QuestionsToolbarContent;
