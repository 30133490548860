import { UserContext, isLoggedIn } from "@parallelpublicworks/entitysync";
import React, { useContext } from "react";
import { navigate } from "gatsby";

const LoginWrap = ({ children, location }) => {
  const userContext = useContext(UserContext);
  if (!isLoggedIn(userContext)) {
    navigate("/", {
      state: { referrer: location.pathname },
    });
    return <></>;
  } else {
    return children;
  }
};

export default LoginWrap;
