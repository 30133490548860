import React from "react";
import { Pagination } from "carbon-components-react";

function QuestionsPagination({
  setCurrentPage,
  setPageSize,
  currentPage,
  pageSize,
  totalItems,
}) {
  return (
    <Pagination
      backwardText="Previous page"
      forwardText="Next page"
      itemsPerPageText="Items per page:"
      page={currentPage}
      pageNumberText="Page Number"
      pageSize={pageSize}
      pageSizes={[10, 20, 30, 50, 100]}
      totalItems={totalItems}
      onChange={(e) => {
        setPageSize(e.pageSize);
        setCurrentPage(e.page);
        if (typeof window !== "undefined") {
          const offsetTop =
            document.querySelector("#questions-data-table").offsetTop - 80;
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }
      }}
    />
  );
}

export default QuestionsPagination;
