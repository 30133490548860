import React, { useState } from "react";

import slugify from "slugify";
import cx from "classnames";
import { useWindowScroll } from "beautiful-react-hooks";
import Layout from "gatsby-theme-carbon/src/components/Layout";
import PageHeader from "gatsby-theme-carbon/src/components/PageHeader";
import NextPrevious from "gatsby-theme-carbon/src/components/NextPrevious";
import PageTabs from "gatsby-theme-carbon/src/components/PageTabs";
import Main from "gatsby-theme-carbon/src/components/Main";
import { useStaticQuery, graphql } from "gatsby";
import { BrowserUnsupported } from "../../components";
import styles from "gatsby-theme-carbon/src/components/Utils/Utils.module.scss";
import BackToTopBtn from "gatsby-theme-carbon/src/components/BackToTopBtn";

const Default = ({ pageContext, children, location }) => {
  const [hidden, setHidden] = useState(true);
  const onScroll = useWindowScroll();

  onScroll(() => {
    if (hidden && window.scrollY > 300) {
      setHidden(false);
    }
    if (!hidden && window.scrollY < 300) {
      setHidden(true);
    }
  });

  const data = useStaticQuery(graphql`
    query {
      allNodeTest {
        edges {
          node {
            drupal_id
            title
            relationships {
              field_questions {
                drupal_id
              }
            }
          }
        }
      }
    }
  `);

  const { frontmatter = {}, titleType } = pageContext;
  const { tabs, theme: frontmatterTheme, description, keywords } = frontmatter;

  let extratabs = [];

  if (data.allNodeTest && data.allNodeTest.edges && data.allNodeTest.edges.length > 0) {
    data.allNodeTest.edges.forEach(({ node }) => {
      extratabs.push(node.title);
    });
  }

  let the_tabs = [];
  if (tabs && tabs.length > 0) {
    the_tabs = [...tabs, ...extratabs];
  }

  const slug = location.pathname;
  const getCurrentTab = () => {
    if (!the_tabs || the_tabs.length === 0) return "";
    return (
      slug.split("/").filter(Boolean).slice(-1)[0] ||
      slugify(the_tabs[0], { lower: true })
    );
  };

  const currentTab = getCurrentTab();
  const theme = frontmatterTheme;
  const title = (
    <span>
      AUPO <em>Medical Student Exam Question Bank</em>
    </span>
  );
  return (
    <Layout
      tabs={the_tabs}
      homepage={false}
      theme={theme}
      pageTitle={`AUPO Medical Student Exam Question Bank`}
      pageDescription={description}
      pageKeywords={keywords}
      titleType={titleType}
    >
      <PageHeader title={title} label="label" tabs={the_tabs} theme={theme} />
      {tabs && <PageTabs slug={slug} tabs={the_tabs} currentTab={currentTab} />}
      <Main padded>
        <BrowserUnsupported />
        {children}
      </Main>
      <NextPrevious
        pageContext={pageContext}
        location={location}
        slug={slug}
        tabs={the_tabs}
        currentTab={currentTab}
      />
      <div
        aria-label="This section contains utilities"
        className={cx(styles.container, { [styles.hidden]: hidden })}
      >
        <BackToTopBtn />
      </div>
    </Layout>
  );
};

export default Default;
