import React, { useContext, useRef, useLayoutEffect, useEffect, useState } from "react";
import cx from "classnames";
import useMedia from "use-media";
import { Login16, Logout16 } from "@carbon/icons-react";
import NavContext from "gatsby-theme-carbon/src/util/context/NavContext";
import { nav, open, divider, link, linkDisabled } from "./Switcher.module.scss";
import { UserContext, isLoggedIn, handleLogout } from "@parallelpublicworks/entitysync";

const Switcher = ({ children }) => {
  const userContext = useContext(UserContext);
  const lgBreakpoint = useMedia("min-width: 1056px");
  const { switcherIsOpen, toggleNavState } = useContext(NavContext);
  const listRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const collapseOpenNavs = function (e) {
      if (e.which === 27) {
        toggleNavState("switcherIsOpen", "close");
      }
    };

    document.addEventListener("keyup", collapseOpenNavs);

    return function cleanup() {
      document.removeEventListener("keyup", collapseOpenNavs);
    };
  }, [toggleNavState]);

  // calculate and update height
  useLayoutEffect(() => {
    if (switcherIsOpen) {
      setHeight(listRef.current.offsetHeight + 40);
    } else {
      setHeight(0);
    }
  }, [listRef, switcherIsOpen]);

  const maxHeight = !lgBreakpoint && switcherIsOpen ? "100%" : `${height}px`;

  const logout = (e) => {
    return handleLogout(userContext, e);
  };

  let user_link;
  if (typeof userContext[0] !== `undefined` && isLoggedIn(userContext)) {
    user_link = (
      <SwitcherLink onClick={logout} href="#" isLogout toggleNavState={toggleNavState}>
        Logout
      </SwitcherLink>
    );
  } else {
    user_link = (
      <SwitcherLink href="/" isLogin toggleNavState={toggleNavState}>
        Login
      </SwitcherLink>
    );
  }
  return (
    <nav
      className={cx(nav, { [open]: switcherIsOpen })}
      aria-label="IBM Design ecosystem"
      tabIndex="-1"
      style={{ maxHeight }}
    >
      <ul ref={listRef}>
        <>
          <SwitcherDivider>User Account</SwitcherDivider>
          {user_link}
        </>
      </ul>
    </nav>
  );
};

export const SwitcherDivider = (props) => (
  <li className={divider}>
    <span {...props} />
  </li>
);

export const SwitcherLink = ({
  disabled,
  children,
  isLogin,
  isLogout,
  href: hrefProp,
  toggleNavState,
  onClick,
  ...rest
}) => {
  const href = disabled || !hrefProp ? undefined : hrefProp;
  const className = disabled ? linkDisabled : link;
  const { switcherIsOpen } = useContext(NavContext);
  const openTabIndex = disabled ? "-1" : 0;

  return (
    <li>
      <a
        aria-disabled={disabled}
        role="button"
        tabIndex={switcherIsOpen ? openTabIndex : "-1"}
        className={className}
        href={href}
        {...rest}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          toggleNavState("switcherIsOpen", "close");
        }}
      >
        {children}
        {isLogin && <Login16 />}
        {isLogout && <Logout16 />}
      </a>
    </li>
  );
};

export default Switcher;
