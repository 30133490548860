import React, { useContext } from "react";
import { Link } from "gatsby";
import {
  Header as ShellHeader,
  HeaderMenuButton,
  SkipToContent,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from "carbon-components-react";
import { User20, Close20 } from "@carbon/icons-react";
import cx from "classnames";

import NavContext from "gatsby-theme-carbon/src/util/context/NavContext";

import {
  header,
  switcherButtonOpen,
  skipToContent,
  headerName,
  collapsed,
  headerButton,
  switcherButton,
} from "./Header.module.scss";

const Header = ({ children }) => {
  const { toggleNavState, switcherIsOpen, searchIsOpen } = useContext(NavContext);

  return (
    <ShellHeader aria-label="Header" className={header}>
      <SkipToContent href="#main-content" className={skipToContent} />
      <HeaderMenuButton
        className={cx("bx--header__action--menu", headerButton)}
        aria-label="Open menu"
        onClick={() => {
          toggleNavState("leftNavIsOpen");
          toggleNavState("switcherIsOpen", "close");
        }}
      />
      <Link
        className={cx(headerName, {
          [collapsed]: searchIsOpen,
        })}
        to="/"
      >
        {children}
      </Link>
      <nav className="bx--header__nav">
        <ul className="bx--header__menu-bar">
          <li>
            <Link className="bx--header__menu-item" tabIndex="0" to="/about">
              <span className="bx--text-truncate--end">How to Use this Site</span>
            </Link>
          </li>
        </ul>
      </nav>
      <HeaderGlobalBar>
        <HeaderGlobalAction
          className={cx(headerButton, switcherButton, {
            [switcherButtonOpen]: switcherIsOpen,
          })}
          aria-label="Switch"
          onClick={() => {
            toggleNavState("switcherIsOpen");
            toggleNavState("searchIsOpen", "close");
            toggleNavState("leftNavIsOpen", "close");
          }}
        >
          {switcherIsOpen ? <Close20 /> : <User20 />}
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </ShellHeader>
  );
};

const DefaultHeaderText = () => (
  <>
    <span>AUPO</span> Medical Student Exam Question Bank
  </>
);

Header.defaultProps = {
  children: <DefaultHeaderText />,
};

export default Header;
