import React from "react";
import { isChrome, isOpera, isEdge } from "react-device-detect";
import { InlineNotification } from "gatsby-theme-carbon";

function BrowserUnsupported() {
  const browserUnsupported = !isChrome && !isEdge && !isOpera;
  if (!browserUnsupported) return <></>;
  return (
    <InlineNotification className="empty-test-notification" kind="error">
      <strong>Unsupported Browser.</strong> The test download function is only supported
      by Google Chrome, Microsoft Edge and Opera.
    </InlineNotification>
  );
}

export default BrowserUnsupported;
